import cx from 'classnames';
import Button from '@common_button';
import Image from '@common_image';
import Typography from '@common_typography';

const ErrorContent = (props) => {
    const { statusCode, title } = props;

    return (
        <div
            className={cx(
                'px-4 flex flex-col items-center justify-center',
                'min-h-[calc(100vh-21rem)]',
            )}
        >
            {statusCode === 404 ? (
                <Image
                    src="/assets/img/404.webp"
                    width={0}
                    height={0}
                    className="w-[254px] mb-[30px]"
                    useContainer={false}
                />
            ) : (
                <Typography className="mb-12 !text-9xl !text-yellow-300">{statusCode}</Typography>
            )}
            <Typography className="mb-5 text-center !font-semibold !text-4xl !leading-9">
                {statusCode === 404 ? 'Oops! Halaman yang kamu tuju tidak ditemukan.' : title}
            </Typography>
            <Typography className="mb-[30px] text-center !text-[13px] !font-medium !text-neutral-500 !leading-[18px]">
                Silakan cari produk dengan menu yang tersedia di atas, atau kembali ke homepage.
            </Typography>
            <Button
                className="group !bg-neutral-white hover:!bg-neutral-black border border-neutral-black !py-2"
                link="/"
            >
                <Typography className={cx('!font-bold !text-[13px] !text-neutral-black uppercase group-hover:!text-neutral-white')}>
                    Kembali ke Homepage
                </Typography>
            </Button>
        </div>
    );
};

export default ErrorContent;
